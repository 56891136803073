
  import {createControllersWithDescriptors, initAppForPageWrapper} from 'yoshi-flow-editor-runtime/build/esm/viewerScript.js';
  
  
            import controller0 from '/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/src/components/AlbumsGOGOOI/controller.ts';

  
  import * as viewerApp from '/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/src/viewer.app.ts';
    var importedApp = viewerApp;

  import {
    
  } from 'yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';

  var sentryConfig = {
      DSN: 'https://e72e679ae3614e728ffef05a9d27e663@sentry.wixpress.com/384',
      id: 'e72e679ae3614e728ffef05a9d27e663',
      projectName: 'albums-ooi',
      teamName: 'exposure',
    };

  var experimentsConfig = {"scope":"albums"};

  var translationsConfig = {"default":"en","defaultTranslationsPath":"/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/src/assets/locales/messages_en.json"};

  var defaultTranslations = {"Dashboard_Main_Album_Details_NumPhotos":"photos","App_Manager_Create_Album_CTA":"Create Album","App_Manager_Manage_Albums_CTA":"Manage Albums","App_Manager_Manage_Albums_Store_CTA":"Manage Albums Store","App_Manager_Customize_Albums_Page_CTA":"Customize Albums Page","App_Manager_Upgrade_Banner_Text":"To sell photos in your albums, ","App_Manager_Upgrade_Banner_Link_Text":"upgrade now.","SETTINGS_PANEL_TITLE":"Photo Albums","GFPP_SETTINGS_LABEL":"Settings","GFPP_MANAGE_LABEL":"Manage Albums","adi.albums-page.settings.description":"Customize the display settings for your Photo Albums. To create new albums, or edit existing ones, click manage albums. ","adi.albums-page.settings.options":"When clicking on an album:","adi.albums-page.settings.dropdown1":"Album opens in a new tab","adi.albums-page.settings.dropdown2":"Albums opens in a popup","adi.albums-page.settings.dropdown3":"Manually","adi.albums-page.settings.manually.cta":"Reorder","adi.albums-page.settings.sort":"Sort Albums by: ","adi.albums-page.settings.sort.option1":"Newest first","adi.albums-page.settings.sort.option2":"Oldest first","adi.albums-page.settings.display":"Choose which Albums to display","adi.albums-page.settings.display.CTA":"Edit Album Visibility","adi.albums-page.settings.order.header":"Albums Order","adi.albums-page.settings.order.description":"Drag & drop your albums in the order that you want them to appear. ","adi.albums-page.settings.order.title.albums-list":"Album Order Display","adi.albums-page.settings.album-visibility-header":"Edit Album Visibility","adi.albums-page.settings.album-visibility-description":"Choose which albums will be displayed on your site. ","adi.albums-page.settings.album-visibility-title":"Album Visibility","adi.albums-page.settings.main-CTA":"Manage Albums"};

  import biLogger from '/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/node_modules/@wix/bi-logger-photo-users/dist/src/index.js';

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "albums-ooi",
    biConfig: {"owner":"@wix/bi-logger-photo-users","visitor":"@wix/bi-logger-photo-users","enableUniversalEvents":false},
    appName: "Albums OOI Test",
    appDefinitionId: "13ff8629-c1fc-e289-e81f-bc8c8968e9d6",
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    optionalDeps: {
      
    },
  });

  export const createControllers = createControllersWithDescriptors({
     
  }, [{ method: controller0,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scope":"albums"},
          defaultTranslations: defaultTranslations,
          biLogger: biLogger,
          biConfig: {"owner":"@wix/bi-logger-photo-users","visitor":"@wix/bi-logger-photo-users","enableUniversalEvents":false},
          controllerFileName: "/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/src/components/AlbumsGOGOOI/controller.ts",
          appName: "Albums OOI Test",
          appDefinitionId: "13ff8629-c1fc-e289-e81f-bc8c8968e9d6",
          projectName: "albums-ooi",
          componentName: "AlbumsGOGOOI",
          id: "1413db53-d0ad-f2f2-f9a4-0dfc2e886b1e" }], "all");
